import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import {
  Box,
  Divider,
  ListItem,
  OrderedList,
  Link as StyledLink,
  Center,
  VStack,
  Text,
  LinkBox,
  Stack,
} from "@chakra-ui/layout";
import { useColorModeValue as mode } from "@chakra-ui/react";
import { Header } from "../components/header";
import { Button } from "@chakra-ui/button";
import { Contract } from "../pages/{ContractsJson.slug}";
import { useScrollPosition } from "../hooks/use-scroll-position";
import useFetch from "use-http";
import { Config } from "../../data/config";

interface Params {
  contract: Contract;
}

export function ContractNavigation({ contract }: Params) {
  const [hideOnScroll, setHideOnScroll] = useState(false);
  const [data, setData] = React.useState(null);
  const { get, post, response, loading, error } = useFetch(
    Config.platform.apiUrl
  );

  React.useEffect(() => {
    const contractID = contract.platformID;

    load(contractID);
  }, []);

  async function load(id: number) {
    const initialData = await get(`/paddle/template/${id}`);
    if (response.ok) setData(initialData);
  }

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const scroll = currPos.y * -1 + window.outerHeight;
      const totalHeight = window.document.documentElement.scrollHeight;

      const isShow = totalHeight - 500 < scroll;

      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  return (
    <>
      {/* TODO */}
      <LinkBox
        display={hideOnScroll ? "none" : "block"}
        mb={10}
        bg={{
          sm: mode("gray.50", "gray.700"),
        }}
        // color={{
        //   sm: mode("white", "gray.700"),
        // }}
        shadow={{
          sm: "base",
        }}
        rounded={{
          sm: "md",
        }}
        overflow="hidden"
        transition="all 0.2s"
        _hover={{
          shadow: {
            sm: "lg",
          },
        }}
      >
        <Link to={`${Config.platform.url}/documents?page=1`}>
          <Center m={5}>
            <VStack>
              <Header.H4 fontWeight="bold">
                Fillo tani me {contract.causativeTitle}
              </Header.H4>
              <Text>
                Legit të vjen në ndihmë, duke të udhëzuar hap pas hapi në
                shkrimin e kontratës.
              </Text>
              {/* <Text>
                {!loading && data
                  ? `Shkarko dokumentin e përshtatur për vetëm ${data?.price} Euro.`
                  : ""}
              </Text> */}
              <Box my={5}></Box>
              <Button
                size={"lg"}
                fontWeight={"normal"}
                px={6}
                colorScheme={"legit"}
                bg={"legit.400"}
                _hover={{ bg: "legit.500" }}
              >
                Gjenero dokumentin
              </Button>
            </VStack>
          </Center>
        </Link>
      </LinkBox>

      <Header.H4>Pasqyra</Header.H4>
      <Divider my={5} />
      <Stack color="legit.600" ml={5} divider={<Divider />}>
        {contract.content
          .filter(({ name }) => name.startsWith("header"))
          .map(({ params }, index) => {
            return (
              <StyledLink>
                <Link to={`#sec${index + 1}`}>
                  {index + 1}. {params.content}
                </Link>
              </StyledLink>
            );
          })}
      </Stack>
    </>
  );
}
