import React from "react";
import {
  Box,
  Link as StyledLink,
  Text,
  Stack,
  VStack,
  Heading,
} from "@chakra-ui/layout";
import { useColorModeValue as mode } from "@chakra-ui/react";
import { Button } from "@chakra-ui/button";
import { Contract } from "../pages/{ContractsJson.slug}";
import useFetch from "use-http";
import { Config } from "../../data/config";
import { Link } from "gatsby";

interface Params {
  contract: Contract;
}

export const ContractCallToAction = (params: Params) => {
  const [data, setData] = React.useState(null);
  const { get, post, response, loading, error } = useFetch(
    Config.platform.apiUrl
  );

  React.useEffect(() => {
    const contractID = params.contract.platformID;

    load(contractID);
  }, []);

  async function load(id: number) {
    const initialData = await get(`/paddle/template/${id}`);
    if (response.ok) setData(initialData);
  }

  return (
    <Box as="section" py="12">
      <Link to={`${Config.platform.url}/documents?page=1`}>
        <Stack
          spacing="6"
          direction={{
            base: "column",
            md: "row",
          }}
          align={{
            md: "center",
          }}
          justify="space-between"
          maxW={{
            base: "xl",
            md: "7xl",
          }}
          mx="auto"
        >
          <Box>
            {/* <Text
              mb="2"
              fontSize="lg"
              fontWeight="semibold"
              color={mode("gray.600", "gray.400")}
            >
              {!loading && data
                ? `Shkarko dokumentin e përshtatur për vetëm ${data?.price} Euro`
                : ""}
            </Text> */}
            <Heading size="xl" fontWeight="extrabold" maxW="25ch">
              Gjenero {params.contract.causativeTitle} në vetëm pesë minuta
            </Heading>
          </Box>
          <Stack
            direction={{
              base: "column",
              sm: "column",
            }}
            spacing={{
              base: "1",
              sm: "2",
            }}
          >
            <Button
              size="lg"
              colorScheme="legit"
              bg={"legit.400"}
              _hover={{ bg: "legit.500" }}
            >
              Fillo tani
            </Button>
            {/* <Text color={"gray.500"} fontWeight="bold">
              {!loading && data
                ? `Për vetëm ${data?.price} Euro`
                : ""}
            </Text> */}
          </Stack>
        </Stack>
      </Link>
    </Box>
  );
};
