import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../layouts";
import { RenderComponents } from "../components";
import { Title } from "../components/title";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import {
  Box,
  Flex,
  Link as StyledLink,
  Text,
  Heading,
} from "@chakra-ui/layout";
import { useColorModeValue as mode } from "@chakra-ui/react";
// @ts-ignore
import * as styles from "../styles/ContractsJson.module.css";
import { ContractNavigation } from "../components/contract-navigation";
import { ContractCallToAction } from "../components/contract-call-to-action";
import { ContractBreadcrumb } from "../components/contract-breadcrumb";

export type Component = {
  type: string;
  name: string;
  params: { content?: string; children?: string };
};

export type Contract = {
  type: "business" | "services" | "employment";
  title: string;
  causativeTitle: string;
  slug: string;
  content: Array<Component>;
  platformID?: number
};

const ContractTemplate = (props) => {
  const contract = props.data.contractsJson;
  let sectionNumber = 0;

  return (
    <Layout location={props.location}>
      <Box my={10}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: "3xl", sm: "4xl", lg: "5xl" }}
        >
          <Text as={"span"}>{contract.title}</Text>
        </Heading>

        <ContractBreadcrumb contract={contract} />

        <Box className={styles.flex}>
          <Box className={`${styles.content} ${styles.smorder2}`}>
            <RenderComponents
              items={contract.content}
              modifyProps={(item) => {
                if (item.name.startsWith("header")) {
                  sectionNumber++;
                  return { id: `sec${sectionNumber}` };
                }
                return {};
              }}
            />
            <ContractCallToAction contract={contract} />
          </Box>
          <Box className={`${styles.aside} ${styles.smorder1}`}>
            <Box className={styles.navigation}>
              <ContractNavigation contract={contract} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default ContractTemplate;

export const pageQuery = graphql`
  query ($slug: String!) {
    contractsJson(slug: { eq: $slug }) {
      id
      title
      causativeTitle
      type
      platformID
      content {
        name
        type
        params {
          __typename
          title
          content
          contents
          caption
          list
          table {
            body {
              value
              colSpan
              rowSpan
            }
            header {
              value
              colSpan
              rowSpan
            }
          }
        }
        __typename
      }
    }
  }
`;
