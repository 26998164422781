import { Breadcrumb, BreadcrumbItem } from "@chakra-ui/breadcrumb";
import { Link as StyledLink } from "@chakra-ui/layout";
import { Contract } from "../pages/{ContractsJson.slug}";

interface Params {
  contract: Contract;
}

const contractTypeLabels: { [k in Contract["type"]]: string } = {
  business: "Biznes",
  employment: "Punësim",
  services: "Shërbime",
};

export function ContractBreadcrumb(params: Params) {
  const contractType = params.contract.type;

  return (
    <Breadcrumb my={3}>
      <BreadcrumbItem>
        <StyledLink href="/kontrata">Dokumente ligjore</StyledLink>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <StyledLink href={`/kontrata#${contractType}`}>
          {contractTypeLabels[contractType]}
        </StyledLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
}
